<template>
  <button 
    class="catalogues__button"
    @click="$emit('showModal')"
    >
    Crear Catálogos
  </button>
</template>

<script>
export default {
    name: 'CreateCatalogueButton'
}
</script>

<style>

</style>
